<template>
    <div>
        <actions-buttons v-only-super-admin>
            <div class="user-selector-wrapper">
                <user-selector
                    :users="lead_users"
                    :selected="selected_users"
                    :show_tooltips="true"
                    :fixed_list="true"
                    :single_select="true"
                    @change="handleSelectUsers"
                />
            </div>

            <el-select
                v-model="filter_status"
                multiple
                collapse-tags
                placeholder="Select"
                style="margin-right: 10px; width: 280px;"
                class="desktop-only"
            >
                <el-option
                    v-for="item in filter_status_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>

            <el-button @click="handleClickOnNewProject">
                New
            </el-button>
        </actions-buttons>

        <div
            v-for="(group, idx) in projects_filtered"
            :key="idx.toString()"
            class="group"
        >
            <ul class="list">
                <project-list-item
                    v-for="project in group.projects"
                    :key="project.id"
                    :class="group.name + '-background'"
                    :group="group"
                    :project="project"
                    @click="handleClickOnProject(project)"
                />
            </ul>

            <hr />
        </div>
    </div>
</template>

<script>
import ActionsButtons from '@/components/generic/ActionsButtons';
import ProjectListItem from './components/ProjectListItem';
import {PROJECT_STATUS} from '@/enums';

import UserSelector from '@/components/generic/UserSelector';

import UserMixin from '@/mixins/user.mixin';

export default {
    name: 'projects-list',
    components: {
        ActionsButtons,
        ProjectListItem,
        UserSelector,
    },
    mixins: [UserMixin],

    data() {
        return {
            filter_status_options: [
                {
                    label: 'Lead',
                    value: PROJECT_STATUS.LEAD,
                    sort: 3,
                },
                {
                    label: 'Active',
                    value: PROJECT_STATUS.ACTIVE,
                    sort: 1,
                },
                {
                    label: 'Maintenance',
                    value: PROJECT_STATUS.MAINTENANCE,
                    sort: 2,
                },
                {
                    label: 'Archived',
                    value: PROJECT_STATUS.ARCHIVED,
                    sort: 4,
                },
            ],
            filter_status: this.$store.getters.user.projects_filter || [
                PROJECT_STATUS.ACTIVE,
                PROJECT_STATUS.MAINTENANCE,
            ],
            selected_users: [],
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projects;
        },
        projects_filtered() {
            if (!this.filter_status) return [];
            return this.filter_status
                .map((status) => {
                    const results = this.projects.filter((project) => {
                        if (this.selected_users.length) {
                            // skip project if it doesn't match user filter
                            if (!this.isSelectedProjectLead(project))
                                return false;
                        }
                        // include project if it matches this status
                        return project.status == status;
                    });

                    return {
                        name: status,
                        projects: results,
                        sort:
                            this.filter_status_options.find(
                                (s) => s.value === status
                            )?.sort ?? 0,
                    };
                })
                .filter((group) => {
                    return group.projects.length > 0;
                })
                .slice(0)
                .sort((a, b) => {
                    return a.sort - b.sort;
                });
        },
        lead_users() {
            return this.$store.getters.users
                .map((u) => u.id)
                .filter((u) => this.isUserProjectLead(u));
        },
    },
    watch: {
        filter_status: function (val) {
            const filters =
                val.length === 0
                    ? [PROJECT_STATUS.ACTIVE, PROJECT_STATUS.MAINTENANCE]
                    : val;

            this.filter_status = filters;

            this.$store.dispatch('updateCurrentUser', {
                key: 'projects_filter',
                value: filters,
            });

            // reset user selector on filter status change
            this.selected_users = [];
        },
    },
    methods: {
        handleClickOnProject(project) {
            this.$router.push({
                name: 'project_detail',
                params: {
                    project_id: project.id,
                },
            });
        },
        handleClickOnNewProject() {
            this.$router.push({
                name: 'project_new',
            });
        },
        handleSelectUsers(selected) {
            this.selected_users = selected;
        },
        isUserProjectLead(user) {
            for (const project of this.projects) {
                if (
                    this.filter_status.includes(project.status) &&
                    this.isSelectedProjectLead(project, [user])
                )
                    return true;
            }
            return false;
        },
        isSelectedProjectLead(project, users = this.selected_users) {
            return project.team?.find((teamMember) => {
                return (
                    users.includes(
                        this.$options.filters.fireRef2id(teamMember.user)
                    ) &&
                    this.product_owner_role?.id ===
                        this.$options.filters.fireRef2id(teamMember.role)
                );
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.group {
    hr {
        width: 100%;
        margin: 20px 0;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }

    &:last-of-type hr {
        display: none;
    }

    .list {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            cursor: pointer;
            margin: 0 0 10px 0;
            padding: 0 20px 0 15px;
            border-radius: 5px;
            background-color: $grey;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: relative;
            min-height: 85px;
        }
    }
}
.user-selector-wrapper {
    display: inline-block;
    margin-right: 10px;
}

::v-deep .avatar-tooltip {
    border: 1px solid red;
    z-index: 9999 !important;
}
</style>
